export default [
    {"id" : 0,
        "title" : "excellence",
        "desc" : "We are passionate about tech, and invest in becoming fluent in a variety of different programming languages, we consider ourselves proud polyglots. We like being familiar with all the tools that allow us to do what we do, and it means appreciating them and keeping up with all the upcoming technology that we will probably fall in love with too. "
    },
    {"id" : 1,
        "title" : "attention to details",
        "desc" : "There is an art in structure and in clearly communicating details that may otherwise seem complex. We feel the key is understanding people, exercising empathy, and using the tools at our disposal to bridge the gap between idea and experience. We consider ourselves architects of innovation, and use a variety of tools to translate our values into every aspect of what we do and what we show."
    },
    {"id" : 2,
        "title" : "strategy",
        "desc" : "Our five step process is our way of guaranteeing that we manifest an idea into results with a method that works with order, precision, expertise and passion. By dividing our processes into strategic phases, we can ensure solid footing for our clients to measure our progress, work and outcome. This way, we can provide a map for our clients to know exactly what they are achieving through our services and products. Each step is a base camp, and the results are when we summit."
    }
]