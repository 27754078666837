import React,{Component} from 'react';

import Carousel from './_carousel/Carousel';
import Item from './_carousel/Item'
import Web from './_services/web'

const servicesDescription = [
    {
        title: 'Web Development',
        description: 'We develop web applications for your business. We use the latest technologies to create a modern and responsive web application. We also provide maintenance and support for your web application.'
    },
    {
        title: 'Software Development',
        description: 'We develop software across various platforms whether that would be mobile, web , desktop or server applications to suit your needs.'
    },
    {
        title: 'Database',
        description: 'Our database experts can provide guidance on everything from database design and implementation to optimization.'
    },
    {
        title: 'Sharepoint',
        description: 'Collaboration and process automation made easy. Let us help with: Team collaboration, Office 365, Record management, Content management.'
    },
    {
        title: 'UI/UX & Branding',
        description: 'A Brand is an identity, a community, and a statement. It defines a business by what it says and the reputation it has made. We help our customers hone their vision and position it to their target market.'
    },
    {
        title: 'Mobile Development',
        description: 'We develop mobile applications for your business. We use the latest technologies to create a modern and responsive mobile application. We also provide maintenance and support for your mobile application.'
    },
]

class CarouselPage extends Component {
    render(){
       return (
           <div>
               <Carousel
                title={"Carousel"}
               >
                     {servicesDescription.map((service, index) => {
                        return (
                            <Item key={index} label={service.title}>
                                <Web title={service.title} description={service.description} />
                            </Item>
                        )
                    }, this)}
               </Carousel>
           </div>
       )
    }
}

export default CarouselPage;