import React, { useRef } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight, faCircle} from "@fortawesome/free-solid-svg-icons";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_hbnxuf5', 'template_gsac6o92', form.current, 'qFxyM_1386OyPvkVr')
            .then((result) => {
                alert("Message Sent Successfully");
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
    };
        return (
            <div className={"ContactFormContent"}>
                <div className={"wrapper columns-span-2"}>
                    <div className={"equal left"}>

                    </div>
                    <div className={"equal right"}>
                        <h3 className={"ContactFormTitle"}>contact form</h3>
                        <form ref={form} onSubmit={sendEmail}>
                            <div className={"formGroup"}>
                                <label>Name</label>
                                <input type="text" name="user_name"/>
                            </div>
                            <div className={"formGroup"}>
                                <label>Company</label>
                                <input type="text" name="user_company"/>
                            </div>
                            <div className={"formGroup"}>
                                <label>Email</label>
                                <input type="email" name="user_email"/>
                            </div>
                            <div className={"formGroup"}>
                                <label>Message</label>
                                <textarea name="message"></textarea>
                            </div>
                            <button type="submit">Submit
                                <span className="fa-layers fa-fw">
                                    {/* <FontAwesomeIcon icon={faCircle}/>*/}
                                    <FontAwesomeIcon icon={faAngleRight} mask={['far', 'circle']} />
                                </span>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        );
}

export default ContactForm;